.comparison-form form {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

.comparison-form .visible {
    display: block;
}

.comparison-form .hidden {
    display: none;
}

.comparison-form h3 {
    font-size: 1.4rem;
}

.comparison-form .calc-option {
    border: 1px solid #72757b;
	border-radius: 5px;
    padding: 0.8em;
}

.comparison-form .calc-option p {
	color: #000;
	font-size: 1.2em;
	margin: 0;
	padding: 0;
}

.comparison-form .calc-option:hover, .comparison-form .calc-option.active {
	background-color: #eeeeee;
}

.calc-option.image img {
	max-height: 50px;
}

.comparison-form .question {
	margin-bottom: 3rem;
}

.comparison-form .css-1vjinar-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    min-height: auto !important;
}

.plan-container {
	border: 2px solid #72757b;
	border-radius: 5px;
    padding: 15px;
}

.plan-title {
    font-size: 1.2rem;
	font-weight: 700;
}

.plan-summary-divider, .calc-section-divider {
    border-color: rgba(0, 0, 0, 0.8) !important;
}

.plan-badge {
    border-radius: 5px;
    padding: 5px;
    font-size: 0.8rem;
}

.show-plans-text {
    text-decoration: underline;
    cursor: pointer;
}

.warning-text {
    color: #ffa726;
}

.comparison-form .question-container {
    overflow: hidden;
}

/* energy efficiency */

.comparison-form#energyefficiency .calc-section-divider {
    color: #229626;
}

.comparison-form#energyefficiency .calc-option:hover, .comparison-form#energyefficiency .calc-option.active {
	border: 2px solid #229626;
}

.comparison-form#energyefficiency .calc-option:hover p, .comparison-form#energyefficiency .calc-option.active p {
    color: #229626;
    font-weight: bold;
}

.comparison-form#energyefficiency .savings {
    color: #229626;
    font-weight: bold;
}

.comparison-form#energyefficiency .plan-badge {
    color: #229626;
    border: 1px solid #229626;
}

.comparison-form#energyefficiency .show-plans-text {
    color: #229626;
}

/* energy switch */

.comparison-form#energyswitch .calc-section-divider {
    color: #6049ee;
}

.comparison-form#energyswitch .calc-option:hover, .comparison-form#energyswitch .calc-option.active {
	border: 2px solid #6049ee;
}

.comparison-form#energyswitch .calc-option:hover p, .comparison-form#energyswitch .calc-option.active p {
    color: #6049ee;
    font-weight: bold;
}

.comparison-form#energyswitch .savings {
    color: #6049ee;
    font-weight: bold;
}

.comparison-form#energyswitch .plan-badge {
    color: #6049ee;
    border: 1px solid #6049ee;
}

.comparison-form#energyswitch .show-plans-text {
    color: #6049ee;
}
body {
  	margin: 0;
  	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    	sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    	monospace;
}

.App {
	min-height: 100vh;
	overflow: hidden;
}

#main-form {
	height: 100vh;
	padding: 2%;
}

.calc-option {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1px solid #72757b;
	border-radius: 3px;
	padding: 0.6875em;
	height: 100%;
}

.calc-option:hover, .calc-option.active {
	border: 1px solid #229626;
}

/* .calc-option.active {
	animation: pulse 2s infinite;
} */

.calc-option.disabled, .calc-option.disabled:hover {
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
	cursor: not-allowed;
}
.calc-option.disabled p, .calc-option.disabled:hover p {
	color: rgba(0, 0, 0, 0.2) !important;
	font-weight: normal !important;
}

.solarfinder .calc-option:hover, .solarfinder .calc-option.active {
	border: 1px solid #f78404;
}

.lifecompare .calc-option:hover, .lifecompare .calc-option.active {
	border: 1px solid #5ba4e3;
}

.nph .calc-option:hover, .nph .calc-option.active {
	border: 1px solid #015f63;
	background-color: #eeeeee;
}

.calc-option.text-only {
	align-items: flex-start;
	text-align: left;
}

.calc-option p {
	color: #000;
	font-size: 1em;
	margin: 0;
	padding: 0;
}

.calc-option:hover p, .calc-option.active p {
	color: #229626;
}

.calc-option.image {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	min-height: 80px;
}

.solarfinder .calc-option:hover p, .solarfinder .calc-option.active p {
	color: #f78404;
}

.lifecompare .calc-option:hover p, .lifecompare .calc-option.active p {
	color: #5ba4e3;
}

.nph .calc-option:hover p, .nph .calc-option.active p {
	color: #015f63;
}

.secondary-text, .calc-option .secondary-text {
	font-size: 0.8em;
	margin-top: 5px;
	color: rgb(121, 118, 118);
}

.secondary-text.hide, .secondary-text.hide:hover {
	font-size: 0.8em;
	margin-top: 5px;
	color: #fff !important;
}

.recommended-text.bold {
	font-weight: 700;
}

.recommended-text {
	color: #229626 !important;
	text-align: center;
}

.solarfinder .recommended-text {
	color: #f78404 !important;
}

.lifecompare .recommended-text {
	color: #5ba4e3 !important;
}

.nph .recommended-text {
	color: #015f63 !important;
}

.secondary-text.recommended-text.hide, .secondary-text.recommended-text.hide:hover {
	color: #fff !important;
}

.progress-bar-container {
	height: 10%;
}
.question-container {
	height: 80%;
	overflow: scroll;
}
.bottom-container {
	height: 10%;
	background-color: white;
}

.question-subtext {
	color: rgb(121, 118, 118);
}

.question-subtext.hide {
	color: #fff;
}

.summary-box {
	border: 2px solid #229626; 
	border-radius: 10px;
	padding: 20px;
	background-color: #fff;
	z-index: 2;
}

.installer-check {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
}

.installer-check p {
	margin: 0;
	padding: 0;
	margin-left: 5px;
}

.installer-box {
	border: 2px solid #229626; 
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 2rem;
}

.calc-image {
	height: 70px;
}

.progress {
	height: 25px !important;
}

.lightbulb {
	width: 20px;
	margin-top: 5px;
}

.scroll-container {
	overflow-x: hidden;
	overflow-y: hidden; 
}

.error-text, .error-text a {
	color: #dc3545;
}

.question-subtext a {
	color: rgb(121, 118, 118);
}

.nph .progress-bar {
	background-color: #015f63 !important;
}

.newsletter-sub {
	background-color: #e7e7e7;
	padding: 15px;
	border-radius: 5px;
}

@media only screen and (max-width: 768px) {
	.scroll-container {
		overflow-x: hidden;
		overflow-y: scroll; 
	}
	.calc-option p {
		font-size: 0.8em !important;
	}
	.nph .MuiInputBase-input, .iyi .MuiInputBase-input, .solarfinder .MuiInputBase-input, .lifecompare .MuiInputBase-input, .energyefficiency .MuiInputBase-input {
		font-size: 0.8em !important;
	}

	/* #main-form {
		height: 80vh;
	} */

	.progress-bar-container {
		height: auto;
	}
	.question-container {
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.bottom-container {
		height: auto;
	}

	.question-subtext.hide {
		display: none;
	}
} 

/* @keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
} */
/* .solar-cost-widget .results-container {
    border: 2px solid #72757b;
    border-radius: 5px;
    padding: 15px;
} */

.solar-cost-widget {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

.solar-cost-widget p, .solar-cost-widget li, .solar-cost-widget label {
    color: #023a51;
}

.solar-cost-widget label {
    font-weight: 500;
}

.widget-cost-heading {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    color: #229626;
}

.widget-cost {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    color: #229626 !important;
}

.widget-cost {
    margin: 0;
    padding: 0;
}

.panel-message {
    text-align: left;
    background-color: #f9fafb;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    margin-top: 10px;
}

.panel-message p {
    margin: 0;
    padding: 0;
}

.widget-results {
    border: 2px solid #229626;
    border-radius: 5px;
    padding: 20px;
}

.solar-cost-widget .calc-option:hover, .solar-cost-widget .calc-option.active {
	border: 2px solid #229626;
}

.solar-cost-widget .calc-option:hover p, .solar-cost-widget .calc-option.active p {
    color: #229626;
    font-weight: bold;
}

.info-icon {
    opacity: 0.5;
    margin-left: 5px;
}